import { graphql, Link } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Resources from "../components/projects/Resources"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        resources: Array<{
          title: string
          description: string
          url: string
          image: string
          video?: boolean
        }>
        features: Array<{
          title: string
          description: string
        }>
      }
    }
  }
}

// markup
const EventDrivenTemplate = (props: Props) => {
  const resources = props.data.page.frontmatter.resources
  const features = props.data.page.frontmatter.features
  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className={`event-driven`}
    >
      <div className="extra-0">
        <img src={`/img/extra/event-driven-7.svg`} alt="" />
      </div>
      <div className="container">
        <div className="hero-event-driven content py-6">
          <HTMLContent
            content={props.data.page.html}
            className={"markdown links-animate"}
          ></HTMLContent>
          <img className="image img-invert" src={`/img/extra/event-driven-1.svg`} alt="" />
        </div>

        <div className="markdown links-animate content">
          <article className="columns has-big-border-dark-top pt-6 pb-5">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[0].title}
              </h1>
            </div>
            <div className="column is-8">
              <HTMLContent
                className=""
                content={features[0].description}
              ></HTMLContent>
              <div className="pt-5">
                <a
                  href={`https://cloud.spring.io/spring-cloud-static/spring-cloud-stream/current/reference/html/spring-cloud-stream.html#_quick_start`}
                >
                  Getting Started with Stream Processing
                </a>
              </div>
            </div>
          </article>
          <div className="quote mb-6">
            <div className="columns">
              <div className="column image is-4 pr-5 has-text-right-desktop">
                <a href="https://www.youtube.com/watch?v=khzC-VwpFVM">
                  <img src={`/img/extra/event-driven-2.jpg`} alt="Event driven"/>
                  <span className="ico">
                    <FontAwesomeIcon className="icon play-button" icon={faPlay} />
                  </span>
                </a>
              </div>
              <div className="column is-8">
                <div className="content">
                  <q className="is-size-4 has-text-weight-bold has-text-secondary">
                    Spring [Cloud] Stream and Spring solutions help us to have a
                    cohesive solution for both imperative and reactive needs.
                  </q>
                  <div className="has-text-weight-bold pt-4">
                    ANIL GURSEL, SOFTWARE ENGINEER, PAYPAL
                  </div>
                </div>
              </div>
            </div>
          </div>
          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[1].title}
              </h1>
            </div>
            <div className="column is-8">
              <HTMLContent
                className=""
                content={features[1].description}
              ></HTMLContent>
              <div className="pt-5 mt-3">
                <div className="code">
                  <pre className="terminal">
                    <code>
                      <span className="terminal-blue">
                        @SpringBootApplication
                      </span>
                      <br />
                      <span className="terminal-green">public class</span>{" "}
                      Application {`{`}
                      <br />
                      <div className="indent">
                        <span className="terminal-green">
                          public static void
                        </span>{" "}
                        main(String[] args) {`{`}
                        <br />
                        <div className="indent">
                          SpringApplication.run(Application.class, args);
                        </div>
                        {`}`}
                        <br />
                        <br />
                        <span className="terminal-blue">@Bean</span>
                        <br />
                        <span className="terminal-green">public </span>{" "}
                        Function&lt;String, String&gt; uppercase() {`{`}
                        <br />
                        <div className="indent">
                          <span className="terminal-green">return</span> value
                          -&gt; value.toUpperCase();
                        </div>
                        {`}`}
                        <br />
                      </div>
                      {`}`}
                    </code>
                  </pre>
                </div>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[2].title}
              </h1>
            </div>
            <div className="column is-3 pr-5">
              <img src={`/img/extra/event-driven-3.png`} alt="Data Flow" />
            </div>
            <div className="column is-5 pl-5">
              <HTMLContent
                className=""
                content={features[2].description}
              ></HTMLContent>
              <div className="pt-5">
                <a href="https://dataflow.spring.io/">Learn more</a>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[3].title}
              </h1>
            </div>
            <div className="column is-6">
              <HTMLContent
                className=""
                content={features[3].description}
              ></HTMLContent>
              <div className="pt-5">
                <a href="https://www.confluent.io/blog/spring-for-apache-kafka-deep-dive-part-2-apache-kafka-spring-cloud-stream/">
                  Learn more
                </a>
              </div>
            </div>
            <div className="column is-2 pr-5">
              <img
                src={`/img/logos/apache-kakfa.svg`}
                className="img-invert"
                style={{ maxWidth: 200 }}
                alt="Kafka"
              />
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[4].title}
              </h1>
            </div>
            <div className="column is-8">
              <HTMLContent
                className=""
                content={features[4].description}
              ></HTMLContent>
              <div className="pt-5">
                <a href="/projects/spring-amqp">Spring AMQP</a>
                <br />
                <a href="/projects/spring-kafka">Spring for Apache Kafka</a>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[5].title}
              </h1>
            </div>
            <div className="column is-8">
              <HTMLContent
                className=""
                content={features[5].description}
              ></HTMLContent>
              <div className="pt-5">
                <a href="/gs/integration/">
                  Get Started with Spring Integration
                </a>
              </div>
            </div>
          </article>
        </div>

        <div className="has-background-light mt-6 p-6 has-text-centered">
          <div className="py-5">
            <h2 className="is-size-2 is-size-3-mobile has-text-weight-medium pb-6">
              Ready to get started?
            </h2>
            <Link
              className="button is-spring"
              to={`/2019/10/15/simple-event-driven-microservices-with-spring-cloud-stream`}
            >
              TRY THIS TUTORIAL
            </Link>
          </div>
        </div>

        <Resources resources={resources} />
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default EventDrivenTemplate

// graphQL queries
export const pageQuery = graphql`
  query EventDrivenTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        defaultClass
        resources {
          title
          description
          image
          url
          video
        }
        features {
          title
          description
        }
      }
    }
  }
`
